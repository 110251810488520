import * as React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PostMetadataDisplay from '../components/metadata'
import { GatsbyImage } from 'gatsby-plugin-image'
import RenderedRichText from '../components/richText'
import { SEO } from '../components/seo'

const HeroBlock = ({ data }) => (
  <div className="relative">
    <GatsbyImage
      image={data.contentfulHomepageData.heroImage.gatsbyImageData}
      alt={data.contentfulHomepageData.heroImage.description}
      loading="eager"
      className="w-full m-0 responsive-gatsby-image"
    />
    <div className="absolute text-center text-white [text-shadow:2px_1px_9px_hsl(0,0%,0%,.9)] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-max max-w-[70vw] flex flex-col items-center">
      <h1 className="text-[min(9vw,2.5rem)] m-0">
        Calvary Assembly&nbsp;of&nbsp;God
      </h1>
      <p className="mt-1">Valley Head, West Virginia</p>
      <div className="h-[min(29vw,14rem)]"></div>
      <p className="px-2 py-0.5 text-xl rounded-lg leading-8 backdrop-filter backdrop-blur-sm w-max">
        {data.contentfulHomepageData.infoOnHeroImage.infoOnHeroImage
          .split("\n")
          .map((line, i) => (
            <span key={i}>
              {i > 0 && <br />}
              {line}
            </span>
          ))}
      </p>
    </div>
  </div>
)

const IndexPage = ({ data, location }) => {
  return (
    <Layout preNavbarContent={ <HeroBlock data={data} /> } location={location}>
      <div className="m-8 text-center">
        <RenderedRichText richText={data.contentfulHomepageData.infoBelowHeroImage} />
      </div>
      <hr className="my-8" />
      <h2>Latest News</h2>
      <div>
        {
          data.allContentfulNewsPost.edges
            .sort((a,b) => b.node.createdAt - a.node.createdAt)
            .map((edge, i) =>
              <div key={i}>
                <h3 className="mb-0"><Link to={edge.node.newsPostPath}>{edge.node.title}</Link></h3>
                <PostMetadataDisplay post={edge.node} />
              </div>
            )
        }
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    contentfulHomepageData(id: {glob: "*"}) {
      heroImage {
        gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
        description
        contentful_id
        __typename
      }
      infoOnHeroImage {
        infoOnHeroImage
      }
      infoBelowHeroImage {
        raw
      }
    }
    allContentfulNewsPost(limit: 5, sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          author
          createdAt(formatString: "x")
          title
          id
          newsPostPath: gatsbyPath(filePath: "/news/{contentfulNewsPost.title}")
        }
      }
    }
  }
`

export default IndexPage

export const Head = () => (
  <SEO />
)

